//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpQTfENICQDGqdWXPsVG0Q4aoge6nKXY8",
  authDomain: "catly-dd6a8.firebaseapp.com",
  projectId: "catly-dd6a8",
  storageBucket: "catly-dd6a8.firebasestorage.app",
  messagingSenderId: "519766516081",
  appId: "1:519766516081:web:ff038b71923ab93a0616d0",
  measurementId: "G-M2E1CLBBXF",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
